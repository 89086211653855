








import { Component, Vue } from "vue-property-decorator";
import TimeSheetReportCreateForm from "@/components/time-sheet-report-file/TimeSheetReportCreateForm.vue";
import TimeSheetReportTable from "@/components/time-sheet-report-file/TimeSheetReportTable.vue";
import TimeSheetReportFilter from "@/components/time-sheet-report-file/TimeSheetReportFilter.vue";
import { ProjectModule } from "@/store/modules/project-module";

@Component({
  components: {
    TimeSheetReportCreateForm,
    TimeSheetReportFilter,
    TimeSheetReportTable,
  },
})
export default class DownloadReport extends Vue {
  private async created() {
    await ProjectModule.getProjects({
      page: 1,
      itemsPerPage: 1000,
      sortBy: [],
      sortDesc: [],
    });
  }
}
